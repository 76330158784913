import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import Text, { TEXT_COLOR } from '@components/Text';
import CTAButton from '@components/CTAButton';
import { openPopUp } from '@containers/App/containers/PopUp/actions';
import POPUP_TYPES from '@containers/App/containers/PopUp/types';
import { ROUTES } from '@shared/constants';
import ChatService from '@services/chatService';
import SectionLoading from '@components/SectionLoading';
import { isMvpVersion } from '@services/isMvpService';
import { sliceText } from '@utils/textHelpers';
import {
  Wrapper,
  Title,
  InstructionAndResourcesWrapper,
  InstructionWrapper,
  ResourcesLinksWrapper,
  StyledLessonIcon,
  StyledLessonVideoIcon,
  StyledDemoVideoIcon,
  InstructionTitle,
  InstructionDescription, InnerWrapper,
} from './styles';
import {
  startActivityLesson,
  endActivityLesson,
  resetActivityLesson,
} from '../../actions';
import {
  resetExerciseData,
  setExercisePausedFlag,
  setMicrophoneActiveFlag,
  setMonitorActiveFlag,
} from '../../../MonitorHandler/actions';
import { getCourses } from '../../../../actions';
import MonitorHandler from '../../../MonitorHandler';
import { TYPES } from '~/shared/Resources/types';

const maxLessonNameLongLength = 130;

export class LessonMainPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoDemoLink: '',
      videoLessonLink: '',
      lessonProgressTime: 0,
    };
    this.videoTypes = {
      demo: 'demo',
      lesson: 'singleLesson',
    };
    this.historyUnblock = props.history.block(this.getExitConfirmation);
  }

  componentDidMount() {
    const {
      lesson,
      dispatchOpenPopUp,
      dispatchStartActivityLesson,
      activityContext,
    } = this.props;

    if (!_.isEmpty(lesson)) {
      const entityLessonInfo = {
        id: lesson.id,
        version: lesson.version,
      };

      this.setVideoProps();
      dispatchOpenPopUp(POPUP_TYPES.COURSE_VIDEO, this.lessonProps);
      dispatchStartActivityLesson(entityLessonInfo, activityContext);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      lesson,
      dispatchOpenPopUp,
      dispatchStartActivityLesson,
      activityContext,
    } = this.props;
    if (lesson && ((_.isEmpty(prevProps.lesson) && !_.isEmpty(lesson)) || prevProps.lesson?.id !== lesson?.id)) {
      const entityLessonInfo = {
        id: lesson.id,
        version: lesson.version,
      };

      this.setVideoProps();
      dispatchOpenPopUp(POPUP_TYPES.COURSE_VIDEO, this.lessonProps);
      dispatchStartActivityLesson(entityLessonInfo, activityContext);
    }
  }

  componentWillUnmount() {
    this.props.dispatchGetCourses();
  }

  updateLessonProgressTime = (lessonProgressTime) => {
    this.setState({ lessonProgressTime });
  };

  setVideoProps() {
    const { lesson } = this.props;

    this.demoProps = {
      videoName: lesson.name,
      videoUrl: lesson.media.demo,
      type: 'demo',
    };

    this.lessonProps = {
      videoName: lesson.name,
      videoUrl: lesson.media.lesson,
      type: 'singleLesson',
      description: lesson.texts.description,
    };
    this.setState({
      videoDemoLink: this.renderVideoLink(this.videoTypes.demo),
      videoLessonLink: this.renderVideoLink(this.videoTypes.lesson),
    });
  }

  getExitConfirmation = ({ pathname }) => {
    const { lessonInProgress, dispatchOpenPopUp } = this.props;
    if (!lessonInProgress) return true;
    dispatchOpenPopUp(POPUP_TYPES.EXERCISE_EXIT, {
      type: TYPES.LESSON,
      exitExercise: () => this.exitLesson(pathname),
      hideCloseIcon: true,
      disableBackdrop: true,
    });
    return false;
  };

  resetLessonData = () => {
    const {
      dispatchSetMonitorActiveFlag,
      dispatchSetMicrophoneActiveFlag,
      dispatchSetExercisePausedFlag,
    } = this.props;

    if (ChatService.archiveId) {
      dispatchSetExercisePausedFlag(true);
    }
    dispatchSetMonitorActiveFlag(false);
    dispatchSetMicrophoneActiveFlag(false);
  };

  exitLesson = (pathname) => {
    const {
      history,
      dispatchEndActivityLesson,
      currentLessonActivity,
    } = this.props;

    dispatchEndActivityLesson(currentLessonActivity.id);
    this.resetLessonData();
    this.historyUnblock();
    history.push(pathname);
  };

  startLesson = () => {
    const {
      lesson, dispatchStartActivityLesson, activityContext,
    } = this.props;
    const entityLessonInfo = {
      id: lesson.id,
      version: lesson.version,
    };
    dispatchStartActivityLesson(entityLessonInfo, activityContext);
  };

  endLesson = () => {
    const {
      dispatchOpenPopUp,
      history,
      dispatchEndActivityLesson,
      currentLessonActivity,
      isLastLesson,
      skillpartIndex,
      match: {
        params: { courseIndex, skillIndex },
      },
    } = this.props;

    // finish exercise, get summary for the activityExercise + update it in the currentPractice activities
    // clean up completed exercise variables from the state.
    dispatchEndActivityLesson(currentLessonActivity.id);

    const nextLessonPath = `${ROUTES.COURSE_PAGE
    }/${courseIndex}/${skillIndex}/lesson${skillpartIndex + 1}`;
    const practicePath = `${ROUTES.COURSE_PAGE}/${courseIndex}/${skillIndex}/practice0`;

    dispatchOpenPopUp(POPUP_TYPES.LESSON_END, {
      redirectToOverview: this.redirectToOverview,
      isLastLesson,
      goToNextLesson: () => history.push(nextLessonPath, { nextPath: true }),
      goToPractice: () => history.push(practicePath, { nextPath: true }),
      resetExerciseData: this.resetExerciseData,
      hideCloseIcon: true,
      disableBackdrop: true,
      currentLessonActivity,
    });
  };

  redirectToOverview = () => {
    this.historyUnblock();
    this.props.history.push(`${ROUTES.COURSE_PAGE}/${this.props.match.params.courseIndex}`);
  };

  resetExerciseData = () => {
    const {
      lesson,
      dispatchResetExerciseData,
    } = this.props;
    const repetition = _.get(lesson, 'monitor.progress.repetition');
    const repetitionsInSequence = repetition && repetition.on ? repetition.count : 1;
    dispatchResetExerciseData(repetitionsInSequence);
  };

  renderVideoLink(type) {
    const { dispatchOpenPopUp, t } = this.props;

    const linkText = type ? t(`Common.UI.${type}`) : '';
    let videoProps;
    let icon;

    switch (type) {
      case this.videoTypes.demo:
        icon = <StyledDemoVideoIcon />;
        videoProps = this.demoProps;
        break;
      case this.videoTypes.lesson:
        icon = <StyledLessonVideoIcon />;
        videoProps = this.lessonProps;
        break;
      default:
        icon = null;
        videoProps = {};
        break;
    }

    return (
      <CTAButton
        outline
        onClick={() => {
          dispatchOpenPopUp(POPUP_TYPES.COURSE_VIDEO, videoProps);
        }}
        id={`${type}VideoLink`}
      >
        {icon}
        <Text size="T2" textTransform="capitalize">
          {linkText}
        </Text>
      </CTAButton>
    );
  }

  renderInstructionsAndResources() {
    const { lesson, t, i18n } = this.props;
    if (_.isEmpty(lesson)) return null;

    return (
      <InstructionAndResourcesWrapper>
        <InstructionWrapper>
          <InstructionTitle
            size="H3"
            textColor="dark_blue"
            textTransform="capitalize"
            margin="0 0 10px 0"
          >
            {t('Common.UI.instructions')}
          </InstructionTitle>
          <InstructionDescription size="T3" textColor={TEXT_COLOR.DARK_BLUE}>
            {lesson.texts.instructions}
          </InstructionDescription>
        </InstructionWrapper>
        <ResourcesLinksWrapper>
          {!isMvpVersion && this.state.videoDemoLink}
          {this.state.videoLessonLink}
        </ResourcesLinksWrapper>
      </InstructionAndResourcesWrapper>
    );
  }

  render() {
    const { lesson, lessonInProgress, currentLessonActivity } = this.props;
    if (_.isEmpty(lesson)) return null;

    if (!lesson.monitor) {
      return <></>;
    }
    const monitorHandlerProps = {
      startExercise: this.startLesson,
      endExercise: this.endLesson,
      resetExerciseData: this.resetExerciseData,
      updateExerciseProgressTime: this.updateLessonProgressTime,
      currentExercise: lesson,
      exerciseInProgress: lessonInProgress,
      isLesson: true,
      productionDuration: lesson.monitor.pattern.productionDuration,
      exerciseProgressTime: this.state.lessonProgressTime,
    };

    if (_.isEmpty(currentLessonActivity)) {
      return <SectionLoading />;
    }

    return (
      <Wrapper>
        <InnerWrapper>
          <Title>
            <StyledLessonIcon />
            <Text size="T0" weight="bold" textTransform="capitalize">
              {sliceText(lesson.name, maxLessonNameLongLength, true)}
            </Text>
          </Title>
          {this.renderInstructionsAndResources()}
        </InnerWrapper>
        <MonitorHandler {...monitorHandlerProps} />
      </Wrapper>
    );
  }
}

LessonMainPane.propTypes = {
  lesson: PropTypes.object,
  dispatchOpenPopUp: PropTypes.func,
  lessonInProgress: PropTypes.bool,
  currentLessonActivity: PropTypes.object,
  activityContext: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  dispatchStartActivityLesson: PropTypes.func,
  dispatchEndActivityLesson: PropTypes.func,
  dispatchResetActivityLesson: PropTypes.func,
  dispatchResetExerciseData: PropTypes.func,
  isLastLesson: PropTypes.bool,
  skillpartIndex: PropTypes.number,
  dispatchGetCourses: PropTypes.func,
};

export const mapStateToProps = (state) => {
  const currentLesson = state.getIn(['currentLesson']).toJS();
  const { lesson, lessonInProgress, currentLessonActivity } = currentLesson;
  const { activityContext, activeLogOpened } = state.getIn(['course']).toJS();
  return {
    lesson,
    lessonInProgress,
    currentLessonActivity,
    activityContext,
    activeLogOpened,
  };
};

export function mapDispatchToProps(dispatch) {
  return {
    dispatchOpenPopUp: (type, props) => dispatch(openPopUp(type, props)),
    dispatchStartActivityLesson: (entityLessonInfo, activityContext) => dispatch(startActivityLesson(entityLessonInfo, activityContext)),
    dispatchEndActivityLesson: (activityLessonId) => dispatch(endActivityLesson(activityLessonId)),
    dispatchResetActivityLesson: () => dispatch(resetActivityLesson()),
    dispatchResetExerciseData: () => dispatch(resetExerciseData()),
    dispatchGetCourses: () => dispatch(getCourses()),
    dispatchSetMonitorActiveFlag: (value) => dispatch(setMonitorActiveFlag(value)),
    dispatchSetMicrophoneActiveFlag: (value) => dispatch(setMicrophoneActiveFlag(value)),
    dispatchSetExercisePausedFlag: (value) => dispatch(setExercisePausedFlag(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withTranslation(),
  withRouter,
  withConnect,
  withTranslation(),
)(LessonMainPane);
