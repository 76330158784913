import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Text, { TEXT_SIZE, TEXT_COLOR, TEXT_WEIGHT } from '@components/Text';
import SortableTable from '@components/SortableTable';
import {
  getTitle,
  getCellText,
  getActivityTypeTitle,
  getStatusText,
  STATUS_TYPES,
} from '@containers/Admin/containers/PendingDashboard/shared/SharedComponents';
import { SectionHeader } from '@containers/Admin/containers/PendingDashboard/shared/SharedComponents/styles';
import Input from '@containers/Admin/containers/PendingDashboard/shared/SharedComponents/Input';
import DismissButton from '@containers/Admin/shared/DismissButton';
import { Wrapper } from './styles';
import Timestamp from '../../components/Timestamp';
import { PendingTable } from '../PendingTable/PendingTable';

class ActivitiesRequireFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
    };
  }

  onFilterChange = (event) => {
    const { value } = event.target;
    this.setState({ filter: value });
  };

  itemIncludesFilter = (filter) => (item) => {
    const itemContent = JSON.stringify(_.values(item)).toLowerCase();
    return itemContent.includes(filter.toLowerCase());
  };

  renderActivities = () => {
    const { filter } = this.state;
    const { activities } = this.props;
    const filteredActivities = (_.isEmpty(filter)
      ? activities
      : activities.filter(this.itemIncludesFilter(filter))).filter((activity) => activity.entity);

    const { onDismiss, onActivitySelect, t } = this.props;

    const tableProps = {
      items: filteredActivities,
      onRowSelect: onActivitySelect,
      showShortTable: true,
      config: [
        {
          title: getTitle('Common.Roles.user'),
          getDisplay: (activity) => getCellText(activity.user.fullName, activity.visits.length === 0),
          width: '16.6%',
          sortable: true,
          sortField: 'user.fullName',
        },
        {
          title: getTitle('UI.activityType'),
          getDisplay: getActivityTypeTitle,
          width: '16.6%',
          sortable: false,
          sortField: 'activityType',
        },
        {
          title: getTitle('UI.activityName'),
          getDisplay: (activity) => getCellText(activity?.entity?.name, activity.visits.length === 0),
          width: '16.6%',
          sortable: true,
          sortField: 'entity.name',
        },
        {
          title: getTitle('UI.activityTime'),
          getDisplay: (activity) => (
            <Timestamp
              timestamp={activity.activityCreated.at}
              isUnvisited={activity.visits.length === 0}
            />
          ),
          width: '16.6%',
          sortable: true,
          sortField: 'activityCreated.at',
        },
        {
          title: getTitle('Common.UI.status'),
          getDisplay: (activity) => getStatusText(
            this.props.t(`Common.Statuses.${activity?.summary?.hasPassed ? 'done' : 'incomplete'}`),
            activity?.summary?.hasPassed
              ? STATUS_TYPES.DONE
              : STATUS_TYPES.FAILED,
          ),
          width: '16.6%',
          sortable: true,
          sortField: 'summary.hasPassed',
        },
        {
          title: '',
          getDisplay: (activity) => (
            <DismissButton
              className="dismissButton"
              onClick={(event) => {
                event.stopPropagation();
                onDismiss(activity);
              }}
            >
              <span className="text">
                {t('Actions.Instance.dismiss')}
              </span>
            </DismissButton>
          ),
          sortable: false,
          sortField: 'dismiss',
          width: '16.6%',
        },
      ],
    };
    return <PendingTable config={tableProps.config} filter={this.state.filter} onRowSelect={tableProps.onRowSelect} activities={tableProps.items} />;
  };

  render() {
    const { filter } = this.state;
    const { t } = this.props;
    return (
      <Wrapper>
        <SectionHeader>
          <Text
            size={TEXT_SIZE.T0}
            textColor={TEXT_COLOR.DARK_BLUE}
            weight={TEXT_WEIGHT.BOLD}
            textTransform="capitalize"
          >
            <span className="title">
              {t('Informative.Notifying.requireFeedback')}
            </span>
          </Text>
          <Input
            id="activities-required-filter"
            name="activities-required-filter"
            className="filter"
            value={filter}
            onChange={this.onFilterChange}
            placeholder={t('Common.Placeholders.searchOrFilter')}
          />
        </SectionHeader>
        {this.renderActivities()}
      </Wrapper>
    );
  }
}

ActivitiesRequireFeedback.propTypes = {
  activities: PropTypes.array,
  onDismiss: PropTypes.func,
  onActivitySelect: PropTypes.func,
};

export default compose(
  withTranslation(),
)(ActivitiesRequireFeedback);
