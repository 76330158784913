import {
  call,
  put,
  takeLatest,
  takeEvery,
  all,
  fork,
  join,
} from 'redux-saga/effects';
import { authenticationWrapper } from '@services/errorHandlingService';
import {
  getConversations,
  getFullConversation,
  getUsers,
  createConversation,
  createPost,
  markPostsAsSeen,
} from './services';
import {
  setConversationsAction,
  setFullConversationAction,
  setUsersAction,
  TYPES,
  getConversationsAction,
  getFullConversationAction,
} from './actions';

import mockConversations from './__fixtures__/conversations.json';
import mockFullConversations from './__fixtures__/fullConversations.json';
import mockUsers from './__fixtures__/users.json';
import {
  getFromLocalStorage,
  LOCAL_STORAGE_ITEM,
  removeFromLocalStorage,
} from '@utils/localStorageHelper';

const useFixtures = process.env.USE_FIXTURES;

export function* callGetConversations() {
  yield authenticationWrapper(function* codeBlock() {
    const res = useFixtures ? mockConversations : yield fork(getConversations);
    const conversations = yield join(res);
    yield put(setConversationsAction(conversations));
  });
}

export function* callGetFullConversation({ id }) {
  yield authenticationWrapper(function* codeBlock() {
    const res = yield fork(getFullConversation, id);
    const currentConversation = useFixtures
      ? mockFullConversations.find((item) => item.id === id)
      : yield join(res);
    yield put(setFullConversationAction(currentConversation));
  });
}

export function* callGetUsers() {
  yield authenticationWrapper(function* codeBlock() {
    const res = yield fork(getUsers);
    const users = useFixtures ? mockUsers : yield join(res);
    yield put(setUsersAction(users));
  });
}

export function* callSendMessage({
  payload: { threadId, textMessage, participantIds, conversationId },
}) {
  yield authenticationWrapper(function* codeBlock() {
    if (!useFixtures) {
      if (conversationId) {
        const res = yield fork(createPost, threadId, textMessage);
        const postId = yield join(res);

        yield fork(markPostsAsSeen, postId);
        yield put(getConversationsAction());
        yield put(getFullConversationAction(conversationId));
      } else {
        const response = yield fork(createConversation, participantIds);
        const { newThreadId, newConversationId } = yield join(response);
        const postIdResponse = yield fork(createPost, newThreadId, textMessage);
        const postId = yield join(postIdResponse);

        yield fork(markPostsAsSeen, postId);
        yield put(getConversationsAction());
        yield put(getFullConversationAction(newConversationId));
      }
    }
  });
}

export function* callMarkPostsAsSeen({ ids }) {
  yield authenticationWrapper(function* codeBlock() {
    if (!useFixtures) {
      yield fork(markPostsAsSeen, ids);
    }
  });
}

function* watchCallGetConversations() {
  yield takeLatest(TYPES.GET_CONVERSATIONS, callGetConversations);
}
function* watchCallGetFullConversation() {
  yield takeLatest(TYPES.GET_FULL_CONVERSATION, callGetFullConversation);
}
function* watchCallGetUsers() {
  yield takeLatest(TYPES.GET_USERS, callGetUsers);
}
function* watchCallSendMessage() {
  yield takeEvery(TYPES.SEND_MESSAGE, callSendMessage);
}
function* watchCallMarkPostsAsSeen() {
  yield takeEvery(TYPES.MARK_POSTS_AS_SEEN, callMarkPostsAsSeen);
}

export default function* messagingSaga() {
  yield all([
    fork(watchCallGetConversations),
    fork(watchCallGetFullConversation),
    fork(watchCallGetUsers),
    fork(watchCallSendMessage),
    fork(watchCallMarkPostsAsSeen),
  ]);
}
