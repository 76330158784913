import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Text from '@components/Text';
import { getIconsByType, iconTypes } from '@services/icons';
import { Tooltip } from '@components/Tooltip';
import {
  Wrapper, StepProgress, Step, Circle, Label,
} from './styles';

const MAX_PROGRESS_PERCENT = 100;
const PROGRESS_STATUSES = {
  LOCKED: 'locked',
  COMPLETED: 'completed',
  CURRENT: 'current',
};

class CourseProgress extends Component {
  getSkillsProgress() {
    const { Skills, locationInCourse } = this.props;
    const skillIndex = locationInCourse.skillIndex || 0;
    const skillsProgress = Skills.map(({
      id, progress: skillProgress, subscribed, name,
    }, index) => {
      let progress;
      if ((!subscribed || index > skillIndex) && skillProgress !== MAX_PROGRESS_PERCENT) {
        progress = PROGRESS_STATUSES.LOCKED;
      } else if (skillProgress === MAX_PROGRESS_PERCENT) {
        progress = PROGRESS_STATUSES.COMPLETED;
      } else {
        progress = PROGRESS_STATUSES.CURRENT;
      }
      return {
        id,
        name,
        progress,
      };
    });
    return { skillsProgress, locationIndex: skillIndex };
  }

  checkCourseCompleted = () => this.props.Skills.every(({ progress }) => progress === MAX_PROGRESS_PERCENT);

  render() {
    if (!this.props.locationInCourse) return null;
    const { skillsProgress, locationIndex } = this.getSkillsProgress();
    const isCourseCompleted = this.checkCourseCompleted();
    const CheckMarkIcon = getIconsByType(iconTypes.BOLD_CHECKMARK);
    const skillsAmount = skillsProgress.length;

    return (
      <Wrapper id="courseProgress">
        <StepProgress
          locationIndex={locationIndex}
          skillsAmount={skillsAmount}
          oneStep={skillsAmount === 1}
          isCourseCompleted={isCourseCompleted}
        >
          {skillsProgress.map(({ id, progress, name }, index) => (
            <Tooltip content={name}>
              <Step
                key={id}
                first={index === 0 && skillsAmount !== 1}
                last={index === skillsAmount - 1}
                skillsAmount={skillsAmount}
                oneStep={skillsAmount === 1}
              >
                <Circle progress={progress}>
                  {progress === PROGRESS_STATUSES.COMPLETED && <CheckMarkIcon />}
                </Circle>
                <Label progress={progress}>
                  <Text textTransform="capitalizeFirst" size="T4">
                    {name}
                  </Text>
                </Label>
              </Step>
            </Tooltip>
          ))}
        </StepProgress>
      </Wrapper>
    );
  }
}

CourseProgress.propTypes = {
  locationInCourse: PropTypes.object,
  Skills: PropTypes.arrayOf(PropTypes.object),
};

export default CourseProgress;
