import { fromByteArray } from 'base64-js';
import { ERROR_TYPES, DSP_INVOCATION_TYPES } from '@shared/Resources/Monitor/types';
import { LOCAL_STORAGE_ITEM, getFromLocalStorage } from '@utils/localStorageHelper';
import {
  addSampleData,
  setDSPCallback,
  getOriginalGraphicValue,
  setAnalysisType,
  calibrate,
  calibrateCancel,
} from '../NTDSPService';
import { postPacketToCloud } from '../APIService';

const BUFFER_SIZE = 1024;
const QUEUE_SIZE = 40;
let CALIBRATE_AUDIO_CONTEXT;
let CALIBRATE_MICROPHONE_STREAM;
let MICROPHONE_STREAM;
let packetIndex = 0;
let AUDIO_CONTEXT;
let AUDIO_STREAM;
let SCRIPT_NODE;
let handleSampleData = {}; // this will be set on start Microphone
let audioBuffer = [];
let tmpBuffer = [];
let sending = false;
let activityId = null;

export const setActivityId = async (id) => {
  activityId = id;
  packetIndex = 0;
};

const convertFloatArrayToBase64 = (data) => {
  const int8Data = new Uint8Array(data.buffer);
  const stringArray = fromByteArray(int8Data);
  return stringArray;
};

const sendAudioBufferToCloud = () => {
  const bufferToSend = JSON.stringify({ packetIndex, data: audioBuffer });
  // TODO: TEMP CHANGES!
  // postPacketToCloud({
  //   data: bufferToSend,
  //   activityId,
  //   packetIndex,
  //   type: 'AUDIO',
  // });
  sending = false;
  packetIndex += 1;
  audioBuffer = [];
};

const monitorTypes = {
  NO_ANALYSIS: 0,
  ORIGINAL: 1,
  TWO_SECONDS: 2,
  GENTLE_VOICE: 3,
  LOUD_VOICE: 4,
  VOICE_TRANSITION: 5,
  ONE_SECOND: 6,
  SELF_MANAGED: 7,
};

const parseAudioBuffer = (audioProcessingEvent) => {
  const { inputBuffer } = audioProcessingEvent;
  const data = inputBuffer.getChannelData(0);
  // convert to base64 String
  const stringArray = convertFloatArrayToBase64(data);
  if (sending) {
    tmpBuffer.push(stringArray);
  } else {
    if (tmpBuffer.length > 0) {
      audioBuffer = tmpBuffer;
      tmpBuffer = [];
    }
    audioBuffer.push(stringArray);
    if (activityId && audioBuffer.length >= QUEUE_SIZE) {
      sending = true;
      sendAudioBufferToCloud();
    }
  }
  addSampleData(data);
};

export const startMicrophoneCalibration = () => {
  startCalibrateMicrophone(handleDSPInvocations);
};

const calibrateAudioBuffer = (audioProcessingEvent) => {
  if (CALIBRATE_AUDIO_CONTEXT && CALIBRATE_AUDIO_CONTEXT.state !== 'closed') {
    const { inputBuffer } = audioProcessingEvent;
    const data = inputBuffer.getChannelData(0);
    calibrate(data);
  }
};

const startCalibrateMicrophone = async (addSampleDataFunction) => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: false,
      audio: {
        autoGainControl: false,
        echoCancellation: false,
        noiseSuppression: false,
      },
    });
    /* use the stream */
    handleSampleData = addSampleDataFunction;
    CALIBRATE_AUDIO_CONTEXT = new AudioContext();
    const CALIBRATE_SCRIPT_NODE = CALIBRATE_AUDIO_CONTEXT.createScriptProcessor(BUFFER_SIZE, 1, 1);
    // Give the node a function to process audio events
    CALIBRATE_SCRIPT_NODE.onaudioprocess = calibrateAudioBuffer;
    CALIBRATE_MICROPHONE_STREAM = CALIBRATE_AUDIO_CONTEXT.createMediaStreamSource(stream);
    CALIBRATE_MICROPHONE_STREAM.connect(CALIBRATE_SCRIPT_NODE);
    CALIBRATE_SCRIPT_NODE.connect(CALIBRATE_AUDIO_CONTEXT.destination);
  } catch (err) {
    /* handle the error */
    console.error(err);
  }
};

export const startMicrophone = (addSampleDataFunction, monitorType) => {
  navigator.mediaDevices
    .getUserMedia({
      video: false,
      audio: {
        autoGainControl: false,
        echoCancellation: false,
        noiseSuppression: false,
      },
    })
    .then((stream) => {
      // first set the analysis type
      const monitorCode = monitorTypes[monitorType];
      AUDIO_STREAM = stream;
      const user = getFromLocalStorage(LOCAL_STORAGE_ITEM.USER);
      const lng = user.locale === 'HE' ? 2 : 1;
      setAnalysisType(monitorCode, lng);
      /* use the stream */

      handleSampleData = addSampleDataFunction;
      AUDIO_CONTEXT = new AudioContext();
      SCRIPT_NODE = AUDIO_CONTEXT.createScriptProcessor(BUFFER_SIZE, 1, 1);

      // Give the node a function to process audio events
      SCRIPT_NODE.onaudioprocess = parseAudioBuffer;

      MICROPHONE_STREAM = AUDIO_CONTEXT.createMediaStreamSource(stream);
      MICROPHONE_STREAM.connect(SCRIPT_NODE);
      SCRIPT_NODE.connect(AUDIO_CONTEXT.destination);
    })
    .catch((err) => {
      /* handle the error */
      console.error(err);
    });
};

const handleDSPInvocations = (type, payload, vect) => {
  switch (type) {
    case DSP_INVOCATION_TYPES.ADD_SAMPLE_DATA:
      // do nothing with this information
      break;
    case DSP_INVOCATION_TYPES.ORIGINAL_DSP_ENGINE_ANALYSIS_GRAPHIC:
      handleSampleData(type, getOriginalGraphicValue());
      break;
    case DSP_INVOCATION_TYPES.END_CALIBRATION:
      stopCalibrateMicrophone();
      break;
    // two seconds per syllable, Loud Voice and Gentle Voice errors
    case ERROR_TYPES.nonGentleOnset:
    case ERROR_TYPES.sharpRiseBeginning:
    case ERROR_TYPES.volumeRiseBeginning:
    case ERROR_TYPES.shapeBeginning:
    case ERROR_TYPES.notGentleBeginning:
    case ERROR_TYPES.excessivePeak:
    case ERROR_TYPES.softPeak:
    case ERROR_TYPES.shapeEnd:
    case ERROR_TYPES.volumeFallEnd:
    case ERROR_TYPES.sharpFallEnd:
    case ERROR_TYPES.nonGentleOffset:
    case ERROR_TYPES.notLoudProduction:
    case ERROR_TYPES.notGentleProduction:
    case ERROR_TYPES.notGentleEnd:
    case ERROR_TYPES.nonGentleOnsetSmm:
    case ERROR_TYPES.noLinkingSmm:
    case ERROR_TYPES.tooFlatSmm:
    case ERROR_TYPES.tooFastSmm:
    case ERROR_TYPES.tooSlowSmm:
      handleSampleData(type, payload);
      break;
    case ERROR_TYPES.nonGentleOnsetOsm:
    case ERROR_TYPES.SoftPeakOsm:
    case ERROR_TYPES.noLinkingOsm:
    case ERROR_TYPES.tooFlatOsm:
    case ERROR_TYPES.tooFastOsm:
    case ERROR_TYPES.tooSlowOsm:
      handleSampleData(type, payload, vect);
      break;
    default:
      break;
  }
};

setDSPCallback(handleDSPInvocations);

export const stopMicrophone = () => {
  try {
    if (MICROPHONE_STREAM) {
      MICROPHONE_STREAM.mediaStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    if (AUDIO_CONTEXT && AUDIO_CONTEXT.state !== 'closed') {
      AUDIO_CONTEXT.close();
    }
  } catch (error) {
    console.error(error);
  }
};

const stopCalibrateMicrophone = () => {
  try {
    if (CALIBRATE_MICROPHONE_STREAM) {
      CALIBRATE_MICROPHONE_STREAM.mediaStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    if (CALIBRATE_AUDIO_CONTEXT && CALIBRATE_AUDIO_CONTEXT.state !== 'closed') {
      CALIBRATE_AUDIO_CONTEXT.close();
    }
    calibrateCancel();
  } catch (error) {
    console.error(error);
  }
};

export default {
  startMicrophone,
  stopMicrophone,
  setActivityId,
  startMicrophoneCalibration,
};
