import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import injectReducer from '@utils/injectReducer';
import SectionLoading from '@components/SectionLoading';
import injectSaga from '@utils/injectSaga';
import { ROUTES } from '@shared/constants';
import {
  windowEventListenerCreator,
  windowEventListenerRemover,
} from '@services/windowService';
import reducer from './reducer';
import saga from './saga';
import { openPopUp } from '../../../App/containers/PopUp/actions';
import POPUP_TYPES from '../../../App/containers/PopUp/types';
import ActivitiesRequireFeedback from './containers/ActivitiesRequireFeedback';
import PendingFeedbackHistory from './containers/PendingFeedbackHistory';
import PendingSummary from './components/PendingSummary';
import PendingActiveUsers from './containers/PendingActiveUsers';
import {
  getPendingDashboardData,
  dismissActivity,
  getHistoryFromHours,
  inviteToVideoChat,
  chatSessionOpened,
} from './actions';
import { startNewConversationAction } from '../../../App/containers/Messaging/actions';

import { PageWrapper, Wrapper } from './styles';

class PendingDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: null,
      currentSessionId: '',
      event: null,
    };
  }

  componentDidMount() {
    const {
      requestDashboardData,
      activityHistory: { hours },
    } = this.props;
    requestDashboardData(hours);
    const storageEvent = 'storage';
    windowEventListenerCreator('storage', this.onStorageEvent);
    this.setState({ event: storageEvent });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.chatSession.sessionId && this.props.chatSession.sessionId) {
      const newWindow = window.open(
        `${ROUTES.VIDEO_CHAT}/${this.props.chatSession.sessionId}`,
        'Video Chat',
      );
      if (this.state.currentSessionId !== this.props.chatSession.sessionId) {
        this.setState({ currentSessionId: this.props.chatSession.sessionId });
      }

      this.setState({ tab: newWindow });
      newWindow.focus();
      this.props.dispatchChatOpened();
    }
  }

  componentWillUnmount() {
    if (this.state.event) {
      windowEventListenerRemover(this.state.event, this.onStorageEvent);
    }
  }

  onStorageEvent = (event) => {
    if (event.storageArea !== localStorage) {
      return;
    }
    if (event.key === this.state.currentSessionId) {
      this.state.tab.close();
      this.props.dispatchOpenPopup(POPUP_TYPES.VIDEO_CHAT_SUMMARY, {
        sessionInfo: JSON.parse(event.newValue),
        hideCloseIcon: true,
        disableBackdrop: true,
      });
    }
  };

  onDismissClicked = (activity) => {
    const { dispatchDismissActivity, dispatchOpenPopup } = this.props;
    dispatchOpenPopup(POPUP_TYPES.CONFIRM_DISMISS_ACTIVITY, {
      onConfirm: (reason) => dispatchDismissActivity(activity, reason),
      hideCloseIcon: true,
    });
  };

  onActivitySelect = (activity) => {
    const activityURL = `${ROUTES.ACTIVITY_PAGE}/${activity.type}/${activity.id}`;
    this.props.history.push(activityURL);
  };

  openFeedbackPreview = (type, payload) => {
    const { dispatchOpenPopup } = this.props;
    const titleText = this.props.t(`Informative.Statuses.${type === 'DISMISSED' ? 'activityDismissed' : 'feedbackSent'}`);

    dispatchOpenPopup(POPUP_TYPES.FEEDBACK_PREVIEW, {
      hideCloseIcon: true,
      bodyText: payload.text,
      titleText,
    });
  };

  startConversationWithUser = (user) => {
    const { dispatchStartNewConversation } = this.props;
    dispatchStartNewConversation(user);
  };

  getUnreadConversationsNumber = () => {
    const { conversations } = this.props;
    if (!conversations) return 0;
    return conversations.filter(
      (conversation) => conversation.Thread && conversation.Thread.unread,
    ).length;
  };

  usersToShowHandler(activeUsers, user) {
    const userIndex = activeUsers.findIndex(
      (activeUser) => activeUser.id === user.id,
    );
    const usersToShow = [...activeUsers];
    usersToShow.splice(userIndex, 1);
    return usersToShow;
  }

  render() {
    const {
      loading,
      activities,
      activityHistory,
      dispatchChangeHoursFilter,
      activeUsers,
      dispatchInviteToChat,
      user,
    } = this.props;
    const usersToShow = this.usersToShowHandler(activeUsers, user);
    const existingEntities = activities?.filter((a) => a.entity);
    const summaryProps = {
      requireFeedback: existingEntities?.length,
      newMessages: this.getUnreadConversationsNumber(),
    };
    const requireProps = {
      activities,
      onDismiss: this.onDismissClicked,
      onActivitySelect: this.onActivitySelect,
    };
    const historyProps = {
      ...activityHistory,
      onHoursFilterChange: dispatchChangeHoursFilter,
      onFeedbackPreview: this.openFeedbackPreview,
      onActivitySelect: this.onActivitySelect,
    };
    return loading ? (
      <div className="loading">
        <SectionLoading />
      </div>
    ) : (
      <PageWrapper>
        <Wrapper>
          <PendingSummary {...summaryProps} />
          <ActivitiesRequireFeedback {...requireProps} />
          <PendingFeedbackHistory {...historyProps} />
        </Wrapper>
        <PendingActiveUsers
          users={usersToShow}
          onStartVideoChat={dispatchInviteToChat}
          onStartConversation={this.startConversationWithUser}
        />
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const user = state.getIn(['app', 'authentication', 'user']).toJS();
  const { conversations } = state.getIn(['messaging']).toJS();
  if (state.get('pendingDashboard')) {
    const {
      loading,
      activities,
      activeUsers,
      chatSession,
      activityHistory,
    } = state.get('pendingDashboard').toJS();
    return {
      loading,
      activities,
      activeUsers,
      chatSession,
      activityHistory,
      user,
      conversations,
    };
  }
  return { user };
};

export const mapDispatchToProps = (dispatch) => ({
  requestDashboardData: (hours) => {
    dispatch(getPendingDashboardData(hours));
  },
  dispatchDismissActivity: (activity, reason) => {
    dispatch(dismissActivity(activity, reason));
  },
  dispatchChangeHoursFilter: (hours) => {
    dispatch(getHistoryFromHours(hours));
  },
  dispatchOpenPopup: (type, props) => {
    dispatch(openPopUp(type, props));
  },
  dispatchInviteToChat: (inviteList) => {
    dispatch(inviteToVideoChat(inviteList));
  },
  dispatchChatOpened: () => {
    dispatch(chatSessionOpened());
  },
  dispatchStartNewConversation: (user) => {
    dispatch(startNewConversationAction(user));
  },
});

PendingDashboard.defaultProps = {
  activities: [],
};

PendingDashboard.propTypes = {
  activities: PropTypes.array.isRequired,
  activityHistory: PropTypes.object.isRequired,
  activeUsers: PropTypes.array.isRequired,
  requestDashboardData: PropTypes.func,
  dispatchDismissActivity: PropTypes.func,
  dispatchChangeHoursFilter: PropTypes.func,
  dispatchOpenPopup: PropTypes.func,
  dispatchInviteToChat: PropTypes.func,
  dispatchChatOpened: PropTypes.func,
  dispatchStartNewConversation: PropTypes.func,
  loading: PropTypes.bool,
  chatSession: PropTypes.object,
  history: PropTypes.object,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'pendingDashboard', reducer });
const withSaga = injectSaga({ key: 'pendingDashboard', saga });

export default compose(
  withReducer,
  withConnect,
  withRouter,
  withSaga,
  withTranslation(),
)(PendingDashboard);
