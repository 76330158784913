/**
*
* MonitorConfig
*
*/
import { useState } from 'react';
import { useSelector } from 'react-redux';
import MonitorTypes from './components/MonitorTypes';
import MonitorErrors from './components/MonitorErrors';
import MonitorDuration from './components/MonitorDuration';
import MonitorStimulus from './components/MonitorStimulus';

const FILED_BUILDER_TYPE = {
  MONITOR: 'monitor',
  ERROR: 'errors',
  PROGRESS: 'progress',
  STIMULUS: 'stimulus',
  PATTERN: 'pattern',
};

export const MonitorConfig = ({
  payload: { config },
  onChange,
}) => {
  const info = useSelector((state) => state.getIn(['builder', 'info']).toJS() ?? {});

  const [monitor, setMonitor] = useState(info.monitor.monitor);
  const [progress, setProgress] = useState(info.monitor.progress);
  const [errors, setErrors] = useState(info.monitor.errors);
  const [stimulusType, setStimulusType] = useState(config.stimulus);

  const updateField = (field) => (newValue) => {
    switch (field) {
      case FILED_BUILDER_TYPE.MONITOR: {
        setMonitor(newValue);
        onChange({
          valid: true,
          value: {
            ...info.monitor,
            monitor,
          },
        });
        break;
      }
      case FILED_BUILDER_TYPE.ERROR: {
        setErrors(newValue);
        onChange({
          valid: true,
          value: {
            ...info.monitor,
            errors: newValue,
          },
        });
        break;
      }
      case FILED_BUILDER_TYPE.PROGRESS: {
        setProgress(newValue);
        onChange({
          valid: true,
          value: {
            ...info.monitor,
            progress: newValue,
          },
        });
        break;
      }
      case FILED_BUILDER_TYPE.STIMULUS: {
        setStimulusType(newValue);
        onChange({
          valid: true,
          value: {
            ...info.monitor,
            stimulus: newValue,
          },
        });
        break;
      }
      case FILED_BUILDER_TYPE.PATTERN: {
        onChange({
          valid: true,
          value: {
            ...info.monitor,
            pattern: newValue,
          },
        });
        break;
      }
      default: break;
    }
  };

  return (
    <div>
      {monitor && (
        <div>
          <MonitorTypes
            monitor={monitor}
            onChange={updateField(FILED_BUILDER_TYPE.MONITOR)}
            monitorPattern={config.pattern}
            updatePatternField={updateField(FILED_BUILDER_TYPE.PATTERN)}
            stimulusType={stimulusType}
          />
          {errors && (
          <MonitorErrors
            onChange={updateField(FILED_BUILDER_TYPE.ERROR)}
            errors={errors}
          />
          )}
          {progress && (
          <MonitorDuration
            onChange={updateField(FILED_BUILDER_TYPE.PROGRESS)}
            duration={progress}
          />
          )}
          {config.stimulus && (
          <MonitorStimulus
            onChange={updateField(FILED_BUILDER_TYPE.STIMULUS)}
            stimulus={config.stimulus}
          />
          )}
        </div>
      )}
    </div>
  );
};
