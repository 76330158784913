import { styled } from '@mui/material';
import {
  getIconByResourceType,
  getIconsByType,
  iconTypes,
} from '@services/icons';
import Container from '@components/Container';
import { getFlexibleSize, getResponsiveCSS } from '@utils/cssHelpers';
import Text from '@components/Text';
import { COURSE_TYPES } from '@shared/Resources/types';

const PracticeIcon = getIconByResourceType(COURSE_TYPES.PRACTICE);
const DemoVideoIcon = getIconsByType(iconTypes.PLAY_CIRCLE);
const PauseIcon = getIconsByType(iconTypes.PAUSE);

export const StyledPracticeIcon = styled(PracticeIcon)(
  {
    fontSize: 30,
  },
  ({ theme }) => ({
    marginRight: theme.RTL ? -5 : 10,
    marginLeft: theme.RTL ? 10 : -5,
    color: theme.palette.novotalk.$textColorDarkBlue,
  }),
);

export const StyledDemoVideoIcon = styled(DemoVideoIcon)({
  fontSize: 24,
  marginRight: 7,
});

export const StyledPauseIcon = styled(PauseIcon)(
  {
    cursor: 'pointer',
    fontSize: 24,
    padding: 4,
    borderRadius: 2,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorReversed,
    backgroundColor: theme.palette.novotalk.$buttonColorDisabled,
    '&:hover': {
      backgroundColor: theme.palette.novotalk.$pauseIconColorHover,
    },
    '&:active': {
      backgroundColor: theme.palette.novotalk.$pauseIconColorActive,
    },
  }),
);

export const Wrapper = styled('div')(
  {
    padding: '40px 104px 85px 52px',
    overflow: 'auto',
    gridArea: 'PracticeMainPane',
    display: 'flex',
    flexDirection: 'column',
    '@media(min-width: 1280px)': {
      paddingTop: getFlexibleSize(40, 76),
      paddingRight: getFlexibleSize(104, 116),
      paddingLeft: getFlexibleSize(52, 280),
    },
    '@media(min-width: 1920px)': {
      padding: '76px 116px 85px 280px',
    },
  },
  ({ theme }) => ({
    maxHeight: `calc(100vh - ${theme.palette.novotalk.skillPartHeaderHeight}px - ${theme.palette.novotalk.navigationBarHeight}px)`,
    backgroundColor: theme.palette.novotalk.$backgroundColor,
  }),
);

export const InnerWrapper = styled('div')(({theme}) => ({
    direction: theme.RTL ? 'rtl' : 'ltr'
}))

export const PracticeTitles = styled(Container)({
  marginBottom: '10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
});

export const TitlesWrapper = styled(Container)({
  maxWidth: '100%',
  flexDirection: 'column',
  alignItems: 'flex-start',
  flexShrink: 0,
});

export const PreviousWrapper = styled(Container)(
  {
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '12px 15px 10px 5px',
    marginBottom: 27,
    borderRadius: 4,
    border: '1px solid',
    '@media(min-width: 1280px)': {
      marginBottom: getFlexibleSize(27, 30),
      paddingLeft: getFlexibleSize(5, 8),
      paddingRight: getFlexibleSize(15, 18),
    },
    '@media(min-width: 1920px)': {
      marginBottom: 30,
      paddingLeft: 8,
      paddingRight: 18,
    },
  },
  ({ theme }) => ({
    borderColor: theme.palette.novotalk.$borderColorLightBlue,
    backgroundColor: theme.palette.novotalk.$backgroundColorMedium,
  }),
);

export const PreviousProgressWrapper = styled(Container)({
  justifyContent: 'flex-start',
  alignItems: 'baseline',
  '@media(min-width: 1280px)': {
    marginTop: getFlexibleSize(0, 4),
    '& div.progressBar': {
      width: getFlexibleSize(150, 160),
    },
  },
  '@media(min-width: 1920px)': {
    marginTop: 4,
    '& div.progressBar': {
      width: 160,
    },
  },
});

export const SubHeaderWrapper = styled('div')(
  {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    flexShrink: 0,
    paddingTop: '28px',
    borderTop: '1px solid',
  },
  ({ theme }) => ({
    borderColor: theme.palette.novotalk.$borderColorLight,
  }),
);

export const Instructions = styled('div')({
  display: 'flex',
});

export const SequencesProgressWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  minHeight: 26,
});

export const MirrorWrapper = styled('div')(
  {
    flexShrink: 0,
    alignSelf: 'center',
    margin: '20px',
    width: '200px',
  },
  ({ displayMirror }) => ({
    height: displayMirror ? '150px' : '20px',
  }),
);

export const MonitorOverlay = styled('div')(
  {
    position: 'absolute',
    top: '0px',
    minHeight: '170px',
    zIndex: '100',
    alignItems: 'center',
    justifyContent: 'center',
  },
  getResponsiveCSS('width', 780, 1130),
  ({ theme, monitorActive }) => ({
    backgroundColor: theme.palette.novotalk.$monitorOverlayColor,
    display: monitorActive ? 'none' : 'flex',
  }),
);

export const MonitorWrapper = styled('div')(
  {
    position: 'relative',
  },
  getResponsiveCSS('marginTop', 90, 148),
);

export const ProgressWrapper = styled('div')({
  display: 'flex',
  flexShrink: 0,
  alignItems: 'center',
  flexDirection: 'column',
  marginTop: 30,
});

export const StyledButton = styled('button')({
  outline: 'none',
  padding: 0,
});

export const InstructionAndResourcesWrapper = styled('div')(
  {
    width: '100%',
    border: '1px solid',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 200,
    justifyContent: 'space-between',
    padding: '25px 30px 32px 32px',
  },
  ({ theme }) => ({
    borderColor: theme.palette.novotalk.$borderColorLight,
  }),
);

export const InstructionWrapper = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'flex-start',
  },
  ({ theme }) => ({
    [theme.RTL ? 'paddingLeft' : 'paddingRight']: 50,
  }),
);

export const InstructionTitle = styled(Text)({
  minHeight: 35,
  overflowX: 'auto',
});

export const ResourcesLinksWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: '10px 0',
});

export const InstructionDescription = styled(Text)({
  overflowY: 'auto',
  maxHeight: 90,
  paddingRight: 10,
});

export const InstructionAndDemo = styled('div')({
  display: 'flex',
});

export const PracticeName = styled('div')({
  display: 'flex',
  marginBottom: 10,
});

export const IconWrapper = styled('div')({
    width: 30,
    height: 30
})
