import {styled} from "@mui/material";
import CTAButton from '@components/CTAButton';
import { getIconsByType, iconTypes } from '@services/icons';

export const Wrapper = styled('div')(
  {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    alignItems: 'center',
    padding: '0 20px',
  },
);

export const StyledAvatar = styled('img')({
  width: 36,
  height: 36,
  marginRight: 8,
  flexShrink: 0,
  borderRadius: '50%',
  objectFit: 'cover',
});

export const UserInfo = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  lineHeight: '16px',
});

export const PostDate = styled('div')({
  justifySelf: 'center',
});

export const StyledCTAButton = styled(CTAButton)({
  justifySelf: 'end',
  width: 130,
  height: 32,
  borderRadius: 4,
  fontSize: 12,
});

const RemoveIcon = getIconsByType(iconTypes.CLOSE);

export const StyledRemoveIcon = styled(RemoveIcon)(
  {
    justifySelf: 'end',
    cursor: 'pointer',
    fontSize: 28,
    borderRadius: '50%',
  },
  ({ theme }) => ({
    '&:hover': {
      backgroundColor: theme.palette.novotalk.$toggleButtonBgColorHover,
    },
    '&:active': {
      backgroundColor: theme.palette.novotalk.$toggleButtonBgColorActive,
    },
  })
);
