import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { ROUTES } from '@shared/constants';
import TextButton from '@components/TextButton';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import ClickAwayListener from '@components/ClickAwayListener';
import { getMessagingDate } from '@utils/timeHelpers';
import {
  MenuWrapper,
  MenuItem,
  StyledPersonIcon,
  MessageInfoWrapper,
  MessageTextWrapper,
  MenuFooter,
  StyledNewItemIcon,
  IconWrapper,
  StyledLink,
} from './styles';
import { MessageIconWrapper, StyledMessageIcon } from '../../styles';
import {
  startNewConversationAction,
  getFullConversationAction,
  getConversationsAction,
} from '../../../Messaging/actions';

export class MessagesMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMessagesMenuShown: false,
    };
  }

  componentDidMount() {
    const { conversations, getConversations } = this.props;
    if (_.isEmpty(conversations)) {
      getConversations();
    }
  }

  getUnreadConversationsNumber = () => {
    const { conversations } = this.props;
    if (!conversations) {
      return 0;
    }

    return conversations.filter(
      (conversation) => conversation.Thread && conversation.Thread.unread,
    ).length;
  };

  openMessagesMenu = () => {
    const { isMessagesMenuShown } = this.state;
    if (!isMessagesMenuShown) {
      this.setState({ isMessagesMenuShown: true });
    }
  };

  closeMessagesMenu = () => {
    this.setState({ isMessagesMenuShown: false });
  };

  renderMenu = () => {
    const { conversations, history, getFullConversation } = this.props;

    const conversationsToDisplay = conversations
      ? conversations.slice(0, 3)
      : [];

    return conversationsToDisplay.map(({ Thread, Users, id }, index) => {
      const post = Thread.Posts[0];
      const otherUser = Users.filter((user) => !user.self);
      const notSelfUser = otherUser.length ? otherUser[0] : null;
      if (!post || !notSelfUser) return false;
      return (
        <MenuItem
          key={id}
          unread={Thread.unread}
          onClick={() => {
            history.push(ROUTES.MESSAGING, { index });
            getFullConversation(id);
            this.closeMessagesMenu();
          }}
        >
          <StyledPersonIcon />
          <div>
            <MessageInfoWrapper>
              <Text
                size={TEXT_SIZE.H5}
                textColor={TEXT_COLOR.NORMAL}
                weight={Thread.unread ? 'bold' : 'normal'}
              >
                {notSelfUser.fullName}
              </Text>
              <Text size={TEXT_SIZE.H5} textColor={TEXT_COLOR.DISABLED}>
                {getMessagingDate(post.createdAt)}
              </Text>
            </MessageInfoWrapper>
            <MessageTextWrapper>
              <Text
                size={TEXT_SIZE.H5}
                textColor={TEXT_COLOR[Thread.unread ? 'DARK_BLUE' : 'DISABLED']}
                weight={Thread.unread ? 'bold' : 'normal'}
              >
                {post.payload.text}
              </Text>
            </MessageTextWrapper>
          </div>
        </MenuItem>
      );
    });
  };

  renderFooter = () => {
    const {
      startNewConversation,
    } = this.props;
    return (
      <MenuFooter>
        <StyledLink to={ROUTES.MESSAGING} onClick={this.closeMessagesMenu}>
          <TextButton>
            <Text size={TEXT_SIZE.T4}>
              {this.props.t('Common.Messaging.all')}
            </Text>
          </TextButton>
        </StyledLink>
        <StyledLink
          to={ROUTES.MESSAGING}
          onClick={() => {
            startNewConversation();
            this.closeMessagesMenu();
          }}
        >
          <IconWrapper>
            <StyledNewItemIcon />
          </IconWrapper>
          <TextButton>
            <Text size={TEXT_SIZE.T4}>
              {this.props.t('Common.Messaging.newMessage')}
            </Text>
          </TextButton>
        </StyledLink>
      </MenuFooter>
    );
  };

  render() {
    const { isMessagesMenuShown } = this.state;
    return (
      <ClickAwayListener
        onClickAway={() => {
          this.closeMessagesMenu();
        }}
      >
        <MessageIconWrapper
          isActive={isMessagesMenuShown}
          newItems={this.getUnreadConversationsNumber()}
          onClick={this.openMessagesMenu}
        >
          <StyledMessageIcon isActive={isMessagesMenuShown} />
          {isMessagesMenuShown && (
            <MenuWrapper>
              {this.renderMenu()}
              {this.renderFooter()}
            </MenuWrapper>
          )}
        </MessageIconWrapper>
      </ClickAwayListener>
    );
  }
}

MessagesMenu.propTypes = {
  conversations: PropTypes.array,
  history: PropTypes.object,
  startNewConversation: PropTypes.func,
  getFullConversation: PropTypes.func,
  getConversations: PropTypes.func,
};

export const mapStateToProps = (state) => {
  const { conversations } = state.getIn(['messaging']).toJS();
  return { conversations };
};

export const mapDispatchToProps = (dispatch) => ({
  startNewConversation: () => dispatch(startNewConversationAction()),
  getFullConversation: (id) => dispatch(getFullConversationAction(id)),
  getConversations: () => dispatch(getConversationsAction()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withRouter,
  withConnect,
  withTranslation(),
)(MessagesMenu);
