import { ORDERS } from '@shared/constants';

export const FIELD_FOR_SORT = {
  FIELD: 'fullName',
  COLUMN: 'User',
  ORDER: ORDERS.ASC,
};

export const WIDTH_COLUMN = {
  MENTOR: 20,
  SUPERVISOR: 16,
};

export const ROLES = {
  USER: {
    TITLE: 'USER',
    NUMBER: '',
  },
  SUPERVISOR: {
    TITLE: 'SUPERVISOR',
    NUMBER: '30',
  },
};

export const DEFAULT_PAGE_NUMBER = 1;

export const DEFAULT_OFFSET = 0;
