import { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import Text, { TEXT_COLOR, TEXT_SIZE } from '@components/Text';
import { openPopUp } from '@containers/App/containers/PopUp/actions';
import { ROUTES } from '@shared/constants';
import { getSecondsFromFormatTime } from '@utils/timeHelpers';
import CTAButton from '@components/CTAButton';
import TYPES from '@containers/App/containers/PopUp/types';
import MonitorHandler from '../../../MonitorHandler';
import {
  Wrapper,
  StyledDemoVideoIcon,
  InstructionAndResourcesWrapper,
  InstructionWrapper,
  InstructionTitle,
  InstructionDescription,
  ResourcesLinksWrapper,
  StyledPracticeIcon,
  PracticeTitles,
  PracticeName,
  InstructionAndDemo, InnerWrapper,
  IconWrapper,
} from './styles';
import {
  startActivityPractice,
  startActivityExercise,
  endActivityExercise,
  continueActivityPractice,
  resetActivityExercise,
  tryingToExitExerciseAction,
} from '../../actions';
import { resetExerciseData, setMicrophoneActiveFlag, setMonitorActiveFlag } from '../../../MonitorHandler/actions';
import { getCourses } from '../../../../actions';

const DEFAULT_PROGRESS_TIME = '00:00';
const EXERCISE_WORD_LENGTH = 8;
const DECIMAL_BASE = 10;

export class PracticeMainPane extends Component {
  constructor(props) {
    super(props);
    this.historyUnblock = props.history.block(this.getExitConfirmation);
    this.state = {
      loading: false,
      pathname: '',
      exerciseIsGoing: false,
      exerciseProgressTime: 0,
    };
    this.eventListener = null;
  }

  componentDidMount() {
    const { currentExercise, exerciseInProgress, dispatchOpenPopUp } = this.props;
    const { pathname, loading } = this.state;
    if (!_.isEmpty(currentExercise)) {
      this.setVideoProps();
    }

    this.eventListener = window.addEventListener('message', (e) => {
      if (e.data === 'archiveWasStopped' && loading) {
        this.setState({ loading: false });
        dispatchOpenPopUp(TYPES.EXERCISE_EXIT, {
          hideCloseIcon: true,
          disableBackdrop: true,
          exerciseInProgress,
          exitExercise: () => this.exitPractice(pathname),
        });
      }
    });
  }

  componentDidUpdate() {
    this.setVideoProps();
  }

  componentWillUnmount() {
    if (this.historyUnblock) {
      this.historyUnblock();
    }

    if (this.eventListener) {
      window.removeEventListener(this.eventListener);
    }
  }

  updateExerciseProgressTime = (exerciseProgressTime) => {
    this.setState({ exerciseProgressTime });
  };

  updateExerciseProgressTimeWithTimeFormat = () => {
    const { currentExercise } = this.props;
    const timeFormatValue = currentExercise?.monitor?.progress?.value;
    let progressTimeSeconds = 0;

    if (timeFormatValue && (typeof timeFormatValue === 'string') && timeFormatValue.includes(':')) {
      progressTimeSeconds = getSecondsFromFormatTime(currentExercise.monitor.progress.value) ?? 0;
    }
    this.updateExerciseProgressTime(progressTimeSeconds);
  };

  setVideoProps = () => {
    const { closePopUp, currentExercise } = this.props;
    this.demoProps = {
      close: closePopUp,
      videoName: _.get(currentExercise, 'name', ''),
      videoUrl: _.get(currentExercise, 'media.demo', ''),
      type: 'demo',
    };
  };

  getExitConfirmation = ({ pathname }) => {
    const {
      exerciseInProgress,
      practice,
      currentExercise,
      match: { url },
      dispatchOpenPopUp,
      dispatchTryingExitExercise,
    } = this.props;

    const isSamePractice = _.initial(pathname.split('/')).join('/') === _.initial(url.split('/')).join('/');

    if (exerciseInProgress && this.state.exerciseIsGoing) {
      dispatchOpenPopUp(TYPES.EXERCISE_EXIT, {
        hideCloseIcon: true,
        disableBackdrop: true,
        exerciseInProgress: true,
        exitExercise: () => this.exitPractice(pathname),
      });
      return false;
    }

    if ((!exerciseInProgress
      && practice.exercises
      && practice.exercises.length
      && (practice.exercises[0].id === currentExercise.id || isSamePractice))
      || !this.state.exerciseIsGoing
    ) {
      return true;
    }

    this.setState({ loading: true, pathname });
    this.exitPractice(pathname);
    dispatchTryingExitExercise(true);

    return false;
  };

  startExercise = () => {
    const {
      currentPracticeActivity,
      activityContext,
      practice,
      dispatchStartActivityExercise,
      dispatchStartActivityPractice,
      currentExercise,
      dispatchContinueActivityPractice,
      activeLogOpened,
    } = this.props;

    this.setState({ exerciseIsGoing: true });
    dispatchStartActivityExercise();

    const activityPracticeId = _.get(currentPracticeActivity, 'id');
    const entityPracticeInfo = {
      id: practice.id,
      version: practice.version,
    };
    const entityExerciseInfo = {
      id: currentExercise.id,
      version: currentExercise.version,
    };
    if (!activityPracticeId) {
      // create new practice activity + new exercise activity

      dispatchStartActivityPractice(
        entityPracticeInfo,
        entityExerciseInfo,
        activityContext,
        activeLogOpened,
      );
    } else {
      // create new exercise activity under existing practice activity
      dispatchContinueActivityPractice(entityExerciseInfo, {
        ...activityContext,
        PRACTICE: entityPracticeInfo,
        ACTIVITY_PRACTICE: activityPracticeId,
      });
    }
  };

  endExercise = () => {
    const {
      dispatchOpenPopUp,
      history,
      dispatchEndActivityExercise,
      dispatchSetMonitorActiveFlag,
      dispatchSetMicrophoneActiveFlag,
      currentExerciseActivity,
      practice,
      finishPractice,
      currentExercise,
      match: {
        params: {
          courseIndex, skillIndex, skillPartIndex, exerciseIndex,
        },
      },
    } = this.props;
    this.setState({ exerciseIsGoing: false });
    // finish exercise, get summary for the activityExercise + update it in the currentPractice activities
    // clean up completed exercise variables from the state.
    dispatchEndActivityExercise(currentExerciseActivity.id);
    let parsedExerciseIndex = 0;
    // when navigating from inside practice to next exercise sometimes we get the word exercise + index of exercise
    if (exerciseIndex) {
      parsedExerciseIndex = exerciseIndex.includes('exercise') ? parseInt(exerciseIndex.substring(EXERCISE_WORD_LENGTH), 10) : parseInt(exerciseIndex || 0, DECIMAL_BASE);
    }
    const isLastExercise = practice.exercises.length - 1 === parsedExerciseIndex;
    const path = isLastExercise
      ? `${ROUTES.COURSE_PAGE}/${courseIndex}`
      : `${ROUTES.COURSE_PAGE}/${courseIndex}/${skillIndex}/${skillPartIndex}/${parsedExerciseIndex + 1
      }`;
    const exerciseLocation = {
      course: parseInt(courseIndex, 10),
      skill: parseInt(skillIndex.match(/\d+/g)[0], 10),
      practice: parseInt(skillPartIndex.match(/\d+/g)[0], 10),
      exercise: parsedExerciseIndex,
    };
    const withVideo = currentExercise.monitor.monitor.video.on;
    if (withVideo) {
      dispatchSetMonitorActiveFlag(false);
      dispatchSetMicrophoneActiveFlag(false);
      dispatchOpenPopUp(TYPES.EXERCISE_END, {
        redirectToOverview: this.redirectToOverview,
        resetExerciseData: this.resetExerciseData,
        updateExerciseProgressTime: () => this.updateExerciseProgressTimeWithTimeFormat(),
        goToNextStep: isLastExercise ? finishPractice : () => history.push(path),
        isLastExercise,
        hideCloseIcon: true,
        disableBackdrop: true,
        exerciseLocation,
        isExerciseWithVideo: true,
      });
      return;
    }
    dispatchOpenPopUp(TYPES.EXERCISE_END, {
      redirectToOverview: this.redirectToOverview,
      resetExerciseData: this.resetExerciseData,
      updateExerciseProgressTime: () => this.updateExerciseProgressTimeWithTimeFormat(),
      goToNextStep: isLastExercise ? finishPractice : () => history.push(path),
      isLastExercise,
      hideCloseIcon: true,
      disableBackdrop: true,
      exerciseLocation,
    });
  };

  redirectToOverview = () => {
    this.props.dispatchGetCourses();
    this.props.history.push(
      `${ROUTES.COURSE_PAGE}/${this.props.match.params.courseIndex}`,
    );
  };

  resetExerciseData = () => {
    const {
      dispatchResetActivityExercise,
      currentExercise,
      dispatchResetExerciseData,
    } = this.props;
    const repetition = _.get(currentExercise, 'monitor.progress.repetition');
    const repetitionsInSequence = repetition && repetition.on ? repetition.count : 1;
    let progressTime;

    if (currentExercise
      && currentExercise.monitor
      && currentExercise.monitor.progress
      && currentExercise.monitor.progress.value
    ) {
      progressTime = currentExercise.monitor.progress.value;
    } else {
      progressTime = DEFAULT_PROGRESS_TIME;
    }

    const progressTimeSeconds = progressTime.toString().includes(':') ? getSecondsFromFormatTime(progressTime) : progressTime;
    this.updateExerciseProgressTime(progressTimeSeconds);
    dispatchResetExerciseData(repetitionsInSequence);
    dispatchResetActivityExercise();
  };

  exitPractice = (pathname) => {
    const { history } = this.props;
    this.resetExerciseData();
    this.historyUnblock();
    history.push(pathname);
  };

  renderVideoLink = () => {
    const { dispatchOpenPopUp } = this.props;

    return (
      <CTAButton
        outline
        onClick={() => dispatchOpenPopUp(TYPES.COURSE_VIDEO, this.demoProps)}
      >
        <div>
          <StyledDemoVideoIcon />
        </div>
        <Text
          size="T2"
          textTransform="capitalize"
        >
          {this.props.t('Common.UI.demo')}
        </Text>
      </CTAButton>
    );
  };

  renderHeader() {
    const {
      practice,
      currentExercise,
      t,
    } = this.props;

    return (
      <InnerWrapper>
        <PracticeTitles>
          <PracticeName>
            <IconWrapper>
              <StyledPracticeIcon />
            </IconWrapper>
            <Text
              textColor={TEXT_COLOR.DARK_BLUE}
              size={TEXT_SIZE.T2}
              textTransform="capitalize"
            >
              {practice?.name}
            </Text>
          </PracticeName>
          <Text
            textColor={TEXT_COLOR.NORMAL}
            size={TEXT_SIZE.T0}
            textTransform="capitalize"
          >
            {currentExercise?.name}
          </Text>
        </PracticeTitles>
        <InstructionAndResourcesWrapper>
          <InstructionAndDemo>
            <InstructionWrapper>
              <InstructionTitle
                size="H3"
                textColor="dark_blue"
                textTransform="capitalize"
              >
                {t('Common.UI.instructions')}
              </InstructionTitle>
              <InstructionDescription size="T3" textColor={TEXT_COLOR.DARK_BLUE}>
                {currentExercise?.texts?.instructions ?? t('Common.UI.noInstructions')}
              </InstructionDescription>
            </InstructionWrapper>
            <ResourcesLinksWrapper>
              {this.renderVideoLink()}
            </ResourcesLinksWrapper>
          </InstructionAndDemo>
        </InstructionAndResourcesWrapper>
      </InnerWrapper>
    );
  }

  render() {
    const {
      practice, currentExercise, exerciseInProgress, popUpType,
    } = this.props;

    if (_.isEmpty(currentExercise) || !practice || _.isEmpty(practice)) {
      return null;
    }

    const monitorHandlerProps = {
      startExercise: this.startExercise,
      endExercise: this.endExercise,
      resetExerciseData: this.resetExerciseData,
      updateExerciseProgressTime: this.updateExerciseProgressTime,
      currentExercise,
      exerciseInProgress,
      productionDuration: currentExercise.monitor.pattern.productionDuration,
      exerciseProgressTime: this.state.exerciseProgressTime,
    };

    return (
      <Wrapper>
        {this.renderHeader()}
        {popUpType !== TYPES.EXERCISE_END && <MonitorHandler {...monitorHandlerProps} />}
      </Wrapper>
    );
  }
}

PracticeMainPane.propTypes = {
  match: PropTypes.object,
  practice: PropTypes.object,
  dispatchOpenPopUp: PropTypes.func,
  closePopUp: PropTypes.func,
  currentExercise: PropTypes.object,
  exerciseInProgress: PropTypes.bool,
  finishPractice: PropTypes.func,
  history: PropTypes.object,
  dispatchStartActivityExercise: PropTypes.func,
  dispatchEndActivityExercise: PropTypes.func,
  activityContext: PropTypes.object,
  currentPracticeActivity: PropTypes.object,
  currentExerciseActivity: PropTypes.object,
  dispatchContinueActivityPractice: PropTypes.func,
  dispatchStartActivityPractice: PropTypes.func,
  dispatchResetActivityExercise: PropTypes.func,
  dispatchResetExerciseData: PropTypes.func,
  dispatchGetCourses: PropTypes.func,
};

export const mapStateToProps = (state) => {
  const currentPractice = state.getIn(['currentPractice']).toJS();
  const {
    practice,
    currentExercise,
    exerciseInProgress,
    currentPracticeActivity,
    currentExerciseActivity,
  } = currentPractice;
  const { activityContext, activeLogOpened } = state.getIn(['course']).toJS();
  const { type: popUpType } = state.getIn(['popUp']).toJS();
  return {
    practice,
    currentExercise,
    exerciseInProgress,
    currentPracticeActivity,
    activityContext,
    currentExerciseActivity,
    popUpType,
    activeLogOpened,
  };
};

export function mapDispatchToProps(dispatch) {
  return {
    dispatchOpenPopUp: (type, props) => dispatch(openPopUp(type, props)),
    dispatchStartActivityExercise: () => dispatch(startActivityExercise()),
    dispatchEndActivityExercise: (activityExerciseId) => dispatch(endActivityExercise(activityExerciseId)),
    dispatchStartActivityPractice: (
      entityPracticeInfo,
      entityExerciseInfo,
      activityContext,
      activityLogId,
    ) => dispatch(
      startActivityPractice(
        entityPracticeInfo,
        entityExerciseInfo,
        activityContext,
        activityLogId,
      ),
    ),
    dispatchContinueActivityPractice: (entityExerciseInfo, activityContext) => dispatch(continueActivityPractice(entityExerciseInfo, activityContext)),
    dispatchResetActivityExercise: () => dispatch(resetActivityExercise()),
    dispatchResetExerciseData: () => dispatch(resetExerciseData()),
    dispatchGetCourses: () => dispatch(getCourses()),
    dispatchTryingExitExercise: (tryingToExit) => dispatch(tryingToExitExerciseAction(tryingToExit)),
    dispatchSetMonitorActiveFlag: (value) => dispatch(setMonitorActiveFlag(value)),
    dispatchSetMicrophoneActiveFlag: (value) => dispatch(setMicrophoneActiveFlag(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withRouter,
  withConnect,
  withTranslation(),
)(PracticeMainPane);
