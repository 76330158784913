export const TYPES = {
  GET_CONVERSATIONS: 'GET_CONVERSATIONS',
  SET_CONVERSATIONS: 'SET_CONVERSATIONS',
  GET_FULL_CONVERSATION: 'GET_FULL_CONVERSATION',
  SET_FULL_CONVERSATION: 'SET_FULL_CONVERSATION',
  GET_USERS: 'GET_USERS',
  SET_USERS: 'SET_USERS',
  START_NEW_CONVERSATION: 'START_NEW_CONVERSATION',
  SET_CONVERSATION_PARTNER: 'SET_CONVERSATION_PARTNER',
  SEND_MESSAGE: 'SEND_MESSAGE',
  CLOSE_CONVERSATION: 'CLOSE_CONVERSATION',
  MARK_POSTS_AS_SEEN: 'MARK_POSTS_AS_SEEN',
};

export function getConversationsAction() {
  return {
    type: TYPES.GET_CONVERSATIONS,
  };
}

export function setConversationsAction(conversations) {
  return {
    type: TYPES.SET_CONVERSATIONS,
    conversations,
  };
}

export function getFullConversationAction(id) {
  return {
    type: TYPES.GET_FULL_CONVERSATION,
    id,
  };
}

export function setFullConversationAction(currentConversation) {
  return {
    type: TYPES.SET_FULL_CONVERSATION,
    currentConversation,
  };
}

export function getUsersAction() {
  return {
    type: TYPES.GET_USERS,
  };
}

export function setUsersAction(users) {
  return {
    type: TYPES.SET_USERS,
    users,
  };
}

export function startNewConversationAction(user) {
  return {
    type: TYPES.START_NEW_CONVERSATION,
    payload: { user },
  };
}

export function setConversationPartnerAction(user) {
  return {
    type: TYPES.SET_CONVERSATION_PARTNER,
    user,
  };
}

export function sendMessageAction(
  participantIds,
  threadId,
  textMessage,
  conversationId
) {
  return {
    type: TYPES.SEND_MESSAGE,
    payload: {
      threadId,
      textMessage,
      participantIds,
      conversationId,
    },
  };
}

export function markPostsAsSeenAction(ids) {
  return {
    type: TYPES.MARK_POSTS_AS_SEEN,
    ids,
  };
}
