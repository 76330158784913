import { fromJS } from 'immutable';
import _ from 'lodash';
import { Types } from './actions';

export const initialState = fromJS({
  loading: true,
  activities: [],
  activeUsers: [],
  chatSession: {
    loading: false,
    sessionId: null,
  },
  activityHistory: {
    activities: [],
    hours: 168,
    loading: true,
  },
});


const pendingDashboard = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case Types.SET_PENDING_DASHBOARD_DATA: {
      const { activities, activityHistory, slippingAway, activeUsers } = payload;
      const newPageState = {
        activityHistory: {
          loading: false,
          hours: 168,
          activities: activityHistory,
        },
        activities,
        slippingAway,
        activeUsers,
        loading: false,
        chatSession: {
          loading: false,
          sessionId: null,
        },
      };
      return fromJS(newPageState);
    }
    case Types.GET_HISTORY_FROM_HOURS: {
      const activityHistory = state.get('activityHistory').toJS();
      activityHistory.loading = true;
      activityHistory.hours = payload.hours;
      return state.set('activityHistory', fromJS(activityHistory));
    }

    case Types.SET_HISTORY_FROM_HOURS: {
      const { hours, activities } = payload;
      const activityHistory = state.get('activityHistory').toJS();
      activityHistory.loading = false;
      activityHistory.hours = hours;
      activityHistory.activities = activities;
      return state.set('activityHistory', fromJS(activityHistory));
    }

    case Types.CONTACT_USER: {
      return state;
    }

    case Types.DISMISS_ACTIVITY: {
      const { activity: { id, type }, reason } = payload;
      const { activities } = state.toJS();

      const removedItem = _.remove(activities, (item) => item.id === id && item.type === type);
      const activityHistory = state.get('activityHistory').toJS();
      const convertedItem = {
        ...removedItem[0],
        feedbackStatus: { type: 'DISMISSED', text: reason },
        createdAt: new Date().toString(),
        feedbackTime: new Date().getTime().toString(),
      };
      activityHistory.activities.unshift(convertedItem);
      return state.set('activities', fromJS(activities))
        .set('activityHistory', fromJS(activityHistory));
    }

    case Types.INVITE_TO_VIDEO_CHAT: return state.set('chatSession', fromJS({
      loading: true,
      sessionId: null,
    }));
    case Types.SET_CHAT_SESSION_ID: return state.set('chatSession', fromJS({
      loading: false,
      sessionId: payload.sessionId,
    }));
    case Types.CHAT_SESSION_OPENED: return state.set('chatSession', fromJS({
      loading: false,
      sessionId: null,
    }));

    default: {
      return state;
    }
  }
};

export default pendingDashboard;
