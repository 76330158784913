import { ReactElement, useCallback, useMemo } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import SectionLoading from '@components/SectionLoading';
import SortOrder from '@components/SortableTable/components/SortOrder';
import { ORDERS, ROUTES } from '@shared/constants';
import { ROLES, WIDTH_COLUMN } from '../consts';
import { getTableColumn } from '../helpers';
import {
  CellUsers,
  HeaderColumnUsers,
  NoItemsUsers,
  TableHeaderUsers,
  TableRowUsers,
  TableWrapperUsers,
  TableListUsers,
  LoadingOverlayUsers,
} from './styles';
import { putOrder } from '../actions';
import { UserData } from '../../UserPage/user-page-wrapper/components/UserInformation/types';
import { USER_PAGE_TYPES } from '../../UserPage/consts';
import CustomTooltip, { placementTypes } from '../../../../../components/CustomTooltip';

type TableColumn = {
  title: string;
  getDisplay: (data: UserData) => ReactElement;
  width: string;
  sortable: boolean;
  sortField: string;
};

const calculateSpread = (config: TableColumn[]): string => config.map(({ width }) => width || 'auto').join(' ');

type HeaderUserSortableTableProps = {
  spread: string;
  config: TableColumn[];
  orderHandler: () => void;
  order: string;
};

const HeaderUserSortableTable = ({ spread, config, orderHandler, order }: HeaderUserSortableTableProps) => (
  <TableHeaderUsers spread={spread} className="tableHeader">
    {config.map(({ title, sortable }, index) => {
      const isUser = title === 'User';
      return (
        <HeaderColumnUsers key={`${title}`} className="headerCell" isLast={index === config.length - 1}>
          <CellUsers
            noPadding
            titlesUppercase
            pointer={isUser}
            onClick={() => {
              if (isUser) {
                orderHandler();
              }
            }}
          >
            {title}
          </CellUsers>
          {sortable && <SortOrder order={order} />}
        </HeaderColumnUsers>
      );
    })}
  </TableHeaderUsers>
);

type RowUserProps = {
  item: UserData;
  spread: string;
  handleRowSelect: (id: string) => void;
  config: TableColumn[];
};

const RowUser = ({ item, spread, handleRowSelect, config }: RowUserProps) => {
  const rowProps = {
    spread,
    onClick: () => handleRowSelect(item.id),
    key: item.id,
    isHighlighted: false,
  };
  return (
    <TableRowUsers className="row" {...rowProps}>
      {config.map(({ getDisplay }, colIndex) => (
        <CustomTooltip placement={placementTypes.BOTTOM} text={getDisplay && getDisplay(item)} tiny>
          <CellUsers className="cell" key={`Row${item.id}Column${colIndex}`} isLast={colIndex === config.length - 1}>
            {getDisplay && getDisplay(item)}
          </CellUsers>
        </CustomTooltip>
      ))}
    </TableRowUsers>
  );
};

export const UserSortableTable = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const handleRowSelect = useCallback((id: string) => {
    push(generatePath(ROUTES.USERS.PAGE, { id, pageType: USER_PAGE_TYPES.INFORMATION }));
  }, []);
  const { loading, list, order } = useSelector((state: RootStateOrAny) => state.getIn(['usersList']).toJS());

  const { roles } = useSelector((state: RootStateOrAny) => state.getIn(['app', 'authentication', 'user']).toJS());
  const dispatch = useDispatch();
  const isSupervisor: boolean = useMemo(() => roles.includes(ROLES.SUPERVISOR.NUMBER), [roles]);
  const widthColumnByRole = useMemo(
    () => `${isSupervisor ? WIDTH_COLUMN.SUPERVISOR : WIDTH_COLUMN.MENTOR}%`,
    [isSupervisor],
  );
  // @ts-ignore
  const config: TableColumn[] = useMemo(
    () => getTableColumn(widthColumnByRole, isSupervisor),
    [widthColumnByRole, isSupervisor],
  );
  const spread = useMemo(() => calculateSpread(config), [config]);
  const orderHandler = useCallback(() => {
    const orderToPut = order === ORDERS.ASC ? ORDERS.DESC : ORDERS.ASC;
    dispatch(putOrder(orderToPut));
  }, [order]);

  return (
    <TableWrapperUsers>
      {loading && (
        <LoadingOverlayUsers>
          <SectionLoading />
        </LoadingOverlayUsers>
      )}
      <HeaderUserSortableTable spread={spread} config={config} orderHandler={orderHandler} order={order} />
      <TableListUsers>
        {!list.length ? (
          <NoItemsUsers>{t('Informative.Negative.noItems')}</NoItemsUsers>
        ) : (
          <div>
            {list.map((item: UserData) => (
              <RowUser spread={spread} handleRowSelect={handleRowSelect} config={config} item={item} />
            ))}
          </div>
        )}
      </TableListUsers>
    </TableWrapperUsers>
  );
};
