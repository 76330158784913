import { getResourcesDate, RESOURCES_DATE_FORMAT } from "@utils/timeHelpers";
import { getCellText } from "@containers/Admin/containers/PendingDashboard/shared/SharedComponents";
import { formatUserStatus } from "../UserPage/helpers";
import { isDesktop } from "@shared/constants/media-queries";
import format from 'date-fns/format';

/**
 * Generate table columns
 * @param {string} width - columns width
 * @param {boolean} isSupervisor - check if current user is supervisor
 * @returns {React.ReactElement} React element
 */
export const getTableColumn = (width, isSupervisor) => {
  const columns = [
    {
      title: "User",
      getDisplay: ({ fullName }) => getCellText(fullName),
      width,
      sortable: true,
      sortField: "fullName",
    },
    {
      title: "Course",
      getDisplay: ({ course }) =>  getCellText(course?.name),
      width,
      sortable: false,
      sortField: "course.name",
    },
    {
      title: "Skill",
      getDisplay: ({ currentSkill }) => getCellText(currentSkill?.name),
      width,
      sortable: false,
      sortField: "currentSkill.name",
    },
    {
      title: "Last practiced",
      getDisplay: ({ lastPracticed }) => lastPracticed && getCellText(format(new Date(lastPracticed), RESOURCES_DATE_FORMAT)),
      width,
      sortable: false,
      sortField: "lastPracticed",
    },
    {
      title: "Status",
      getDisplay: ({ status }) => getCellText(formatUserStatus(status)),
      width,
      sortable: false,
      sortField: "status",
    },
  ];

  if (isSupervisor) {
    columns.splice(1, 0, {
      title: "Mentor",
      getDisplay: ({ mentor }) => mentor && getCellText(mentor[0]?.fullName),
      width,
      sortable: false,
      sortField: "mentor[0].fullName",
    })
  }

  return columns;
}


export const userListOffset = isDesktop() ? 15 : 9;
