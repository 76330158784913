import {styled} from "@mui/material";
import { getIconsByType, iconTypes } from '@services/icons';
import { getFlexibleSize } from '~/utils/cssHelpers';
import Text from '@components/Text';

const iconStyle = {
  marginTop: '-6px',
  color: 'inherit',
  fontSize: 28,
};

const videoIconStyle = {
  color: 'inherit',
  fontSize: 24,
};

const LessonIcon = getIconsByType(iconTypes.TV);
const LessonVideoIcon = getIconsByType(iconTypes.FILM);
const DemoVideoIcon = getIconsByType(iconTypes.PLAY_CIRCLE);

export const StyledLessonIcon = styled(LessonIcon, {
  shouldForwardProp: (prop) => prop !== 'RTL',
})(iconStyle, ({ theme }) => ({
  [theme.RTL ? 'marginLeft' : 'marginRight']: '10px',
}));

export const StyledLessonVideoIcon = styled(LessonVideoIcon, {
  shouldForwardProp: (prop) => prop !== 'RTL',
})(videoIconStyle, ({ theme }) => ({
  [theme.RTL ? 'marginLeft' : 'marginRight']: '10px',
}));

export const StyledDemoVideoIcon = styled(DemoVideoIcon, {
  shouldForwardProp: (prop) => prop !== 'RTL',
})(videoIconStyle, ({ theme }) => ({
  [theme.RTL ? 'marginLeft' : 'marginRight']: '10px',
}));

export const Wrapper = styled('div')(
  {
    gridArea: 'LessonMainPane',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  ({ theme }) => ({
    maxHeight: `calc(100vh - ${theme.palette.novotalk.skillPartHeaderHeight}px - ${theme.palette.novotalk.navigationBarHeight}px)`,
    backgroundColor: theme.palette.novotalk.$backgroundColor,
    '@media(min-width: 1280px)': {
      paddingTop: getFlexibleSize(10, 27),
      paddingRight: getFlexibleSize(100, 135),
      paddingLeft: getFlexibleSize(100, 135),
    },
    '@media(min-width: 1920px)': {
      padding: theme.RTL ? '27px 135px 25px 45px' : '27px 45px 25px 135px',
    },
  })
);

export const InnerWrapper = styled('div')(({theme}) => ({
    direction: theme.RTL ? 'rtl' : 'ltr'
}))

export const Title = styled('div')(
  {
    marginBottom: 25,
  },
  ({ theme }) => ({
    color: theme.palette.novotalk.$textColorDarkBlue,
  })
);

export const InstructionAndResourcesWrapper = styled('div')(
  {
    width: '100%',
    border: '1px solid',
    borderRadius: '4px',
    display: 'flex',
    minHeight: 200,
    justifyContent: 'space-between',
    padding: '25px 30px 32px 32px',
  },
  ({ theme }) => ({
    borderColor: theme.palette.novotalk.$borderColorLight,
  })
);

export const InstructionWrapper = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'flex-start',
  },
  ({ theme }) => ({
    [theme.RTL ? 'paddingLeft' : 'paddingRight']: 50,
  })
);

export const ResourcesLinksWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: '10px 0',
});

export const InstructionTitle = styled(Text)({
    minHeight: 35,
    overflowX: "auto",
});

export const InstructionDescription = styled(Text)({
  overflowY: "auto",
  minHeight: 90
});
