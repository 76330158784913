import { useTranslation } from 'react-i18next';
import { RootStateOrAny, useSelector } from 'react-redux';
import {
  ReactElement, useCallback, useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import SectionLoading from '@components/SectionLoading';
import {
  TableWrapperPending, LoadingOverlayPending, NoItemsPending, TableListPending,
} from './styles';
import { sortNestedHandler } from './helpers';
import { Activity } from './types';
import { HeaderPendingSortableTable } from './components/HeaderPendingSortableTable';
import { RowPending } from './components/RowPending';

export enum ORDERS {
  ASC = 'asc',
  DESC = 'desc',
}
export type TableColumn = {
  title: string;
  getDisplay: (data: Activity) => ReactElement;
  width: string;
  sortable: boolean;
  sortField: string;
};

type PendingTableProps = {
  filter: string;
  config: TableColumn[];
  onRowSelect: (activity: Activity) => void;
  activities: Activity[];
};

const itemIncludesFilter = (filter: string) => (item: Activity) => {
  const itemContent = JSON.stringify(_.values(item)).toLowerCase();
  return itemContent.includes(filter.toLowerCase());
};

const calculateSpread = (config: TableColumn[]): string => config.map(({ width }) => width || 'auto').join(' ');

export const PendingTable = ({
  filter, config, onRowSelect, activities,
}: PendingTableProps) => {
  const [order, setOrder] = useState<ORDERS>(ORDERS.ASC);
  const [sortField, setSortField] = useState<string>(config && config.length ? config[5].sortField : '');
  const { t } = useTranslation();
  const { loading } = useSelector((state: RootStateOrAny) => state.getIn(['pendingDashboard']).toJS());
  const spread = useMemo(() => calculateSpread(config), [config]);
  const filteredActivities = useMemo(
    () => (_.isEmpty(filter) ? activities : activities.filter(itemIncludesFilter(filter))).filter(
      (activity: Activity) => activity.entity,
    ),
    [activities, filter],
  );

  const localSortedList = useMemo(
    () => sortNestedHandler(sortField, [...filteredActivities], order),
    [filteredActivities, sortField, order],
  );

  const sortFieldHandler = useCallback(
    (field: string) => {
      if (field === sortField) {
        const ord = order === ORDERS.ASC ? ORDERS.DESC : ORDERS.ASC;
        setOrder(ord);
      } else {
        setSortField(field);
        setOrder(ORDERS.ASC);
      }
    },
    [order, sortField],
  );
  return (
    <TableWrapperPending>
      {loading && (
        <LoadingOverlayPending>
          <SectionLoading />
        </LoadingOverlayPending>
      )}
      <HeaderPendingSortableTable
        spread={spread}
        config={config}
        orderHandler={sortFieldHandler}
        order={order}
        sortFieldLocale={sortField}
      />
      <TableListPending>
        {!localSortedList.length ? (
          <NoItemsPending>{t('Informative.Negative.noItems')}</NoItemsPending>
        ) : (
          <div>
            {localSortedList.map((item: Activity) => (
              <RowPending spread={spread} handleRowSelect={onRowSelect} config={config} item={item} />
            ))}
          </div>
        )}
      </TableListPending>
    </TableWrapperPending>
  );
};
